.HomePage {
}

.inputForm {
  padding-top: 5%;
  padding-bottom: 5%;
}

.progressBar {
  margin: 5%;
}

.landingImage {
  padding-top: 5%;
  padding-bottom: 5%;
  width: 90%;
  height: auto;
}

.pogMomentRow {
  cursor: pointer;
}
