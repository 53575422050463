.App {
  text-align: center;
}

body {
  margin: 0;
}

.MuiTypography-h6 {
  font-family: "Squada One", cursive !important;
  font-size: 20;
}

.footer {
  border-top: 1px solid grey;
}